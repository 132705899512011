import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Heading, Text } from '../components/ui';
import Layout from '../components/Layout';
import CompanyActions from '../components/CompanyActions';
import Score from '../components/Score';
import KPIs from '../components/KPIs';
import Compare from '../components/Compare';

const CompanyPageTemplate = ({
  data: {
    impactScore: {
      impactScoreCompany: {
        name,
        number,
        companyUrl,
        ceoPayRatioScore,
        ceoPayRatioBand,
        ceoPayRatioScoreDesc,
        co2EmissionsScore,
        co2EmissionsBand,
        co2EmissionsScoreDesc,
        genderPayGapScore,
        genderPayGapBand,
        genderPayGapScoreDesc,
        paySuppliersPromptlyScore,
        paySuppliersPromptlyBand,
        paySuppliersPromptlyScoreDesc,
        realLivingWageScore,
        realLivingWageBand,
        realLivingWageScoreDesc,
        ukTaxPaidScore,
        ukTaxPaidBand,
        ukTaxPaidScoreDesc,
        ukTaxPaidScoreDescUnit,
        scoreQualififaction,
      },
      impactScoreWeightings,
      impactScoreCompanies,
    },
  },
}) => {
  const [popup, setPopup] = useState(false);

  return (
    <Layout>
      <Helmet title={`${name} | Impact Score`} defer={false} />
      <CompanyActions number={number} setPopup={setPopup} />
      <Heading>{name}</Heading>
      <Text>
        <a href={companyUrl} target="_blank" rel="noopener noreferrer">
          {number}
        </a>
      </Text>
      <Score
        scores={[
          { key: 'uk-tax-paid', score: ukTaxPaidScore },
          { key: 'gender-pay-gap', score: genderPayGapScore },
          { key: 'real-living-wage', score: realLivingWageScore },
          { key: 'co2-emissions', score: co2EmissionsScore },
          { key: 'pay-suppliers-promptly', score: paySuppliersPromptlyScore },
          { key: 'ceo-pay-ratio', score: ceoPayRatioScore },
        ]}
        showScores={scoreQualififaction}
        publicPreferences={[
          {
            key: 'uk-tax-paid',
            weighting: impactScoreWeightings[0].ukTaxPaidWeighting,
          },
          {
            key: 'gender-pay-gap',
            weighting: impactScoreWeightings[0].genderPayGapWeighting,
          },
          {
            key: 'real-living-wage',
            weighting: impactScoreWeightings[0].realLivingWageWeighting,
          },
          {
            key: 'co2-emissions',
            weighting: impactScoreWeightings[0].co2EmissionsWeighting,
          },
          {
            key: 'pay-suppliers-promptly',
            weighting: impactScoreWeightings[0].paySuppliersPromptlyWeighting,
          },
          {
            key: 'ceo-pay-ratio',
            weighting: impactScoreWeightings[0].ceoPayRatioWeighting,
          },
        ]}
      />
      <KPIs
        items={[
          {
            name: 'UK Tax',
            band: ukTaxPaidBand,
            description:
              ukTaxPaidScoreDesc !== null
                ? `<p>UK tax <span>${
                    parseFloat(ukTaxPaidScoreDesc) > 0
                      ? 'paid'
                      : 'rebate received'
                  } £${ukTaxPaidScoreDesc}${ukTaxPaidScoreDescUnit}</span>.</p>`
                : false,
          },
          {
            name: 'Gender Pay',
            band: genderPayGapBand,
            description:
              genderPayGapScoreDesc !== null
                ? `<p>Difference in average hourly wage <span>${genderPayGapScoreDesc}%</span>.</p>`
                : false,
          },
          {
            name: 'Real Living Wage',
            band: realLivingWageBand,
            description:
              realLivingWageScoreDesc !== null
                ? `<p>This company <span>${
                    parseInt(realLivingWageScoreDesc) ? 'does' : 'doesn’t'
                  }</span> pay the RLW.</p>`
                : false,
          },
          {
            name: 'Emissions',
            band: co2EmissionsBand,
            description:
              co2EmissionsScoreDesc !== null
                ? `<p>Annual CO2 emissions <span>${co2EmissionsScoreDesc}</span> tonnes.</p>`
                : false,
          },
          {
            name: 'Paying Suppliers',
            band: paySuppliersPromptlyBand,
            description:
              paySuppliersPromptlyScoreDesc !== null
                ? `<p>Invoices paid late <span>${paySuppliersPromptlyScoreDesc}%</span>.</p>`
                : false,
          },
          {
            name: 'Pay Ratio',
            band: ceoPayRatioBand,
            description:
              ceoPayRatioScoreDesc !== null
                ? `<p>Boss is paid <span>${ceoPayRatioScoreDesc}</span> x the average worker.</p>`
                : false,
          },
        ]}
      />
      {popup && (
        <Compare
          currentCompanyNumber={number}
          companies={impactScoreCompanies}
          setPopup={setPopup}
        />
      )}
    </Layout>
  );
};

export const CompanyPageTemplateQuery = graphql`
  query CompanyPageTemplateQuery($number: String!, $category: String) {
    impactScore {
      impactScoreCompany(number: $number) {
        name
        number
        companyUrl
        co2EmissionsScore
        co2EmissionsBand
        co2EmissionsScoreDesc
        ceoPayRatioScore
        ceoPayRatioBand
        ceoPayRatioScoreDesc
        genderPayGapScore
        genderPayGapBand
        genderPayGapScoreDesc
        paySuppliersPromptlyScore
        paySuppliersPromptlyBand
        paySuppliersPromptlyScoreDesc
        realLivingWageScore
        realLivingWageBand
        realLivingWageScoreDesc
        ukTaxPaidScore
        ukTaxPaidBand
        ukTaxPaidScoreDesc
        ukTaxPaidScoreDescUnit
        scoreQualififaction
      }
      impactScoreWeightings {
        ukTaxPaidWeighting
        genderPayGapWeighting
        realLivingWageWeighting
        co2EmissionsWeighting
        paySuppliersPromptlyWeighting
        ceoPayRatioWeighting
      }
      impactScoreCompanies(
        where: { category: { _eq: $category } }
        order_by: { impactScore: desc }
      ) {
        number
        name
        impactScore
      }
    }
  }
`;

export default CompanyPageTemplate;
