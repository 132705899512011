import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles/utilities';

const StyledCompare = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  left: 20px;
  overflow: auto;
  max-height: calc(100% - 120px);
  background-color: ${standardColours.white};
  border: 1px solid ${brandColours.tertiary};
  border-radius: 22px;
`;

const StyledCompareHeader = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background-color: ${brandColours.secondary};
`;

const StyledCompareHeading = styled.h2`
  color: ${standardColours.white};
  ${fontSize(20)};
`;

const StyledCompareButton = styled.button`
  display: flex;
  padding: 8px 12px;
  ${fontSize(16)};
  color: ${standardColours.white};
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.semibold};
  background-color: ${brandColours.primary};
  border: none;
  border-radius: 22px;

  &:after {
    content: '✕';
    margin-left: 10px;
    ${fontSize(18)};
  }
`;

const StyledCompareLink = styled(Link)`
  display: block;
  padding: 10px 20px;
  color: ${brandColours.secondary};
  font-weight: ${fontWeights.semibold};
  ${fontSize(16)};
  line-height: 1.7;
  border-top: 1px solid ${brandColours.tertiary};

  ${({ highlight }) => {
    if (highlight) {
      return `
        color: ${standardColours.white};
        background-color: ${brandColours.primary};
      `;
    }
  }}
`;

const Compare = ({ currentCompanyNumber, companies, setPopup }) => {
  return (
    <StyledCompare>
      <StyledCompareHeader>
        <StyledCompareHeading>Compare</StyledCompareHeading>
        <StyledCompareButton onClick={() => setPopup(false)}>
          Close
        </StyledCompareButton>
      </StyledCompareHeader>
      {companies.map(({ number, name, impactScore }) => {
        const slug = name
          .toString()
          .toLowerCase()
          .replace(/\s+/g, '-')
          .replace(/[^\w-]+/g, '')
          .replace(/--+/g, '-')
          .replace(/^-+/, '')
          .replace(/-+$/, '');

        return (
          <StyledCompareLink
            key={slug}
            to={`/${slug}`}
            highlight={currentCompanyNumber === number ? 'true' : ''}
          >
            {name} - {impactScore}
          </StyledCompareLink>
        );
      })}
    </StyledCompare>
  );
};

export default Compare;
