import React, { useState } from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  fontWeights,
  fontSize,
} from '../styles/utilities';
import { Svg } from './ui';
import questionMarkIcon from '../images/question-mark-icon.svg';
import rageEmoji from '../images/rage-emoji.svg';
import cryEmoji from '../images/cry-emoji.svg';
import rollingEyesEmoji from '../images/rolling-eyes-emoji.svg';
import smileyEmoji from '../images/smiley-emoji.svg';
import heartEyesEmoji from '../images/heart-eyes-emoji.svg';

const StyledKPI = styled.div`
  position: relative;
  height: 120px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.8s;
  transform-style: preserve-3d;

  ${({ flip }) => {
    if (flip) {
      return `
        transform: rotateY(180deg);
      `;
    }
  }}
`;

const StyledKPISide = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  background-color: ${standardColours.white};
  border-radius: 22px;
  backface-visibility: hidden;

  ${({ back }) => {
    if (back) {
      return `
        transform: rotateY(180deg);
      `;
    }
  }}
`;

const StyledKPIIcon = styled(Svg)`
  height: 48px;
  width: 48px;
`;

const StyledKPIName = styled.p`
  margin-top: 10px;
  ${fontSize(10)};

  ${minBreakpointQuery.tiny`
    ${fontSize(11)};
  `}
`;

const StyledKPIDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  p {
    margin: auto;
    ${fontSize(12)};
    line-height: 1.5;

    ${minBreakpointQuery.tiny`
      ${fontSize(13)};
    `}

    span {
      font-weight: ${fontWeights.semibold};
    }
  }
`;

const KPI = ({ data: { name, band, description } }) => {
  const [flip, setFlip] = useState(false);

  let icon = questionMarkIcon;

  if (band === 1) {
    icon = rageEmoji;
  } else if (band === 2) {
    icon = cryEmoji;
  } else if (band === 3) {
    icon = rollingEyesEmoji;
  } else if (band === 4) {
    icon = smileyEmoji;
  } else if (band === 5) {
    icon = heartEyesEmoji;
  }

  return (
    <StyledKPI flip={flip} onClick={() => description && setFlip(!flip)}>
      <StyledKPISide>
        <StyledKPIIcon image={icon} />
        <StyledKPIName>{name}</StyledKPIName>
      </StyledKPISide>
      <StyledKPISide back="true">
        <StyledKPIDescription
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </StyledKPISide>
    </StyledKPI>
  );
};

export default KPI;
