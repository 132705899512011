import React from 'react';
import styled from 'styled-components';
import KPI from './KPI';

const StyledKPIs = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 30px 0;
`;

const KPIs = ({ items }) => {
  return (
    <StyledKPIs>
      {items.map(item => (
        <KPI key={item.name.replace(/\s+/g, '-').toLowerCase()} data={item} />
      ))}
    </StyledKPIs>
  );
};

export default KPIs;
