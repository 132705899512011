import React, { useState } from 'react';
import styled from 'styled-components';
import {
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles/utilities';
import { Svg } from './ui'
import compareIcon from '../images/compare-icon.svg';
import faceIcon from '../images/face-icon.svg';

const StyledCompanyActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  text-align: center;
`;

const StyledCompanyActionsButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledCompanyActionsButtonIcon = styled(Svg)`
  height: 30px;
  width: 30px;
`;

const StyledCompanyActionsButtonText = styled.span`
  display: block;
  margin-top: 4px;
  color: ${brandColours.secondary};
  font-family: ${brandFonts.primary};
  ${fontSize(10)};
  text-align: center;
`;

const StyledPopup = styled.div`
  position: fixed;
  z-index: 99;
  top: 50%;
  left: 50%;
  padding: 20px;
  width: 240px;
  background-color: ${standardColours.white};
  border: 1px solid ${brandColours.tertiary};
  border-radius: 22px;
  transform: translate(-50%, -50%);
  line-height: 1.7;

  div {
    display: flex;
    margin-top: 12px;

    button {
      padding: 10px;
      width: 50%;
      color: ${standardColours.white};
      font-family: ${brandFonts.primary};
      font-weight: ${fontWeights.semibold};
      background: ${standardColours.red};
      border: none;

      &:first-child {
        background: ${standardColours.green};
      }
    }
  }
`;

const CompanyActions = ({ number, setPopup }) => {
  const [isHelpful, setIsHelpful] = useState(false);

  const handleHelpfulClick = foundHelpful => {
    setIsHelpful(false);

    fetch('/.netlify/functions/submitFoundHelpful', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      //make sure to serialize your JSON body
      body: JSON.stringify({
        companyNumber: number,
        deviceID: localStorage.getItem('impactscore:id'),
        foundHelpful: foundHelpful,
      }),
    })
      .then(response => response.json())
      .then(console.log);
  };

  return (
    <StyledCompanyActions>
      <StyledCompanyActionsButton type="button" onClick={() => setPopup(true)}>
        <StyledCompanyActionsButtonIcon image={compareIcon} />
        <StyledCompanyActionsButtonText>Compare</StyledCompanyActionsButtonText>
      </StyledCompanyActionsButton>
      <StyledCompanyActionsButton
        type="button"
        onClick={() => setIsHelpful(true)}
      >
        <StyledCompanyActionsButtonIcon image={faceIcon} />
        <StyledCompanyActionsButtonText>
          Tell them
        </StyledCompanyActionsButtonText>
      </StyledCompanyActionsButton>
      {isHelpful && (
        <StyledPopup>
          <p>Your views are important. Tell them what you think...</p>
          <div>
            <button onClick={() => handleHelpfulClick(true)}>
              Good, keep going
            </button>
            <button onClick={() => handleHelpfulClick(false)}>
              Not good enough!
            </button>
          </div>
        </StyledPopup>
      )}
    </StyledCompanyActions>
  );
};

export default CompanyActions;
