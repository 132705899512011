import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  standardColours,
  brandColours,
  scoreColours,
  brandFonts,
  fontWeights,
  fontSize,
  standardTransition,
} from '../styles/utilities';

const StyledScore = styled.section`
  margin: 20px 0;
`;

const StyledScoreContainer = styled.div`
  position: relative;
  margin: 0 auto;
  height: 200px;
  width: 200px;
  transition: transform 0.8s;
  transform-style: preserve-3d;

  ${({ flip }) => {
    if (flip) {
      return `
        transform: rotateY(180deg);
      `;
    }
  }}
`;

const StyledScoreSide = styled.div`
  position: absolute;
  padding: 20px;
  width: 100%;
  height: 100%;
  background: conic-gradient(
    ${({ scoreColour }) => scoreColour} ${({ score }) => score}%,
    ${standardColours.white} 0
  );
  border: 1px solid ${brandColours.tertiary};
  border-radius: 50%;
  backface-visibility: hidden;

  ${({ back }) => {
    if (back) {
      return `
        transform: rotateY(180deg);
      `;
    }
  }}
`;

const StyledScoreInner = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  background-color: ${standardColours.white};
  border-radius: 50%;
  backface-visibility: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${({ scoreColour }) => scoreColour};
    opacity: 0.05;
  }
`;

const StyledScoreText = styled.p`
  position: relative;
  ${fontSize(14)};
  font-weight: ${fontWeights.semibold};
  line-height: 1.2;
`;

const StyledScoreTrademark = styled.span`
  display: block;
  margin: 4px 0;
  color: ${brandColours.quaternary};

  span {
    color: ${standardColours.black};
  }
`;

const StyledScoreNumber = styled.span`
  display: block;
  margin: 4px 0;
  font-weight: ${fontWeights.semibold};
  ${fontSize(58)};
  line-height: 1.1;
`;

const StyledScoreButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto 0;
  width: auto;
  color: ${brandColours.secondary};
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.semibold};
  background: none;
  border: 0;
`;

const StyledScoreToggle = styled.span`
  position: relative;
  margin: 0 10px;
  height: 22px;
  width: 54px;
  background-color: ${brandColours.primary};
  border-radius: 22px;

  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    height: 16px;
    width: 16px;
    background-color: ${standardColours.white};
    border-radius: 50%;
    transform: translateX(0);
    ${standardTransition('transform')};

    ${({ flip }) => {
      if (flip) {
        return `
          transform: translateX(200%);
        `;
      }
    }}
  }
`;

const Score = ({ scores, showScores, publicPreferences }) => {
  const [preferencesScore, setPreferencesScore] = useState(0);
  const [publicScore, setPublicScore] = useState(0);
  const [flip, setFlip] = useState(false);

  useEffect(() => {
    const storedPreferences = JSON.parse(
      localStorage.getItem('impactscore:preferences')
    );
    setPreferencesScore(calcScore(scores, storedPreferences));
    setPublicScore(calcScore(scores, publicPreferences));
  }, [scores]);

  const notNullScores = scores.filter(({ score }) => score !== null);

  const calcScore = (scores, preferences = null) => {
    const calculatedScore = (
      scores.reduce((acc, { key, score }) => {
        let weighting = 1;
        if (preferences) {
          const preference = preferences.find(
            preference => preference.key === key
          );
          weighting = preference.weighting;
        }
        return acc + score * weighting;
      }, 0) / notNullScores.length
    ).toFixed();

    if (calculatedScore > 100) {
      return 100;
    } else {
      return calculatedScore;
    }
  };

  const scoreColour = score => {
    let colour = scoreColours.low;

    if (score > 66) {
      colour = scoreColours.high;
    } else if (score > 33) {
      colour = scoreColours.medium;
    }

    return colour;
  };

  return (
    <StyledScore>
      <StyledScoreContainer flip={flip}>
        <StyledScoreSide
          score={preferencesScore}
          scoreColour={scoreColour(preferencesScore)}
        >
          <StyledScoreInner scoreColour={scoreColour(preferencesScore)}>
            <StyledScoreText>
              My
              <StyledScoreTrademark>
                Impact <span>Score ®</span>
              </StyledScoreTrademark>
              <StyledScoreNumber>
                {showScores ? preferencesScore : '?'}
              </StyledScoreNumber>
              / 100
            </StyledScoreText>
          </StyledScoreInner>
        </StyledScoreSide>
        <StyledScoreSide
          score={publicScore}
          scoreColour={scoreColour(publicScore)}
          back="true"
        >
          <StyledScoreInner scoreColour={scoreColour(publicScore)}>
            <StyledScoreText>
              Public
              <StyledScoreTrademark>
                Impact <span>Score ®</span>
              </StyledScoreTrademark>
              <StyledScoreNumber>
                {showScores ? publicScore : '?'}
              </StyledScoreNumber>
              / 100
            </StyledScoreText>
          </StyledScoreInner>
        </StyledScoreSide>
      </StyledScoreContainer>
      <StyledScoreButton alt="true" onClick={() => setFlip(!flip)}>
        My Score <StyledScoreToggle flip={flip}></StyledScoreToggle> Public
        Score
      </StyledScoreButton>
    </StyledScore>
  );
};

export default Score;
